import makeRequest from "@api/makeRequest";
import { Tariff } from "@frontend/kitui";
import { parseAxiosError } from "@root/utils/parse-error";

import type {
  PrivilegeWithoutPrice,
  ServiceKeyToPrivilegesMap,
} from "@root/model/privilege";
import type { GetTariffsResponse } from "@root/model/tariff";
import { removeTariffFromCart } from "@root/stores/user";
import axios from "axios";

interface CreateTariffBody {
  name: string;
  price?: number;
  isCustom: boolean;
  privileges: PrivilegeWithoutPrice[];
}

const API_URL = `${process.env.REACT_APP_DOMAIN}/strator`;

export const getTariffs = async (
  apiPage: number,
  tariffsPerPage: number,
  signal: AbortSignal | undefined
): Promise<[GetTariffsResponse | null, string?]> => {
  try {
    const tariffsResponse = await makeRequest<never, GetTariffsResponse>({
      method: "GET",
      url: `${API_URL}/tariff/getList?page=${apiPage}&limit=${tariffsPerPage}`,
      useToken: true,
      signal,
    });

    return [tariffsResponse];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Не удалось получить список тарифов. ${error}`];
  }
};

export const createTariff = async (
  tariff: CreateTariffBody
): Promise<[Tariff | null, string?]> => {
  try {
    const createTariffResponse = await makeRequest<CreateTariffBody, Tariff>({
      method: "POST",
      url: `${API_URL}/tariff`,
      body: tariff,
      useToken: true,
    });

    return [createTariffResponse];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Не удалось создать тариф. ${error}`];
  }
};

export const getTariffById = async (
  tariffId: string
): Promise<[Tariff | null, string?, number?]> => {
  try {
    const getTariffByIdResponse = await makeRequest<never, Tariff>({
      method: "GET",
      url: `${API_URL}/tariff/${tariffId}`,
      useToken: true,
    });

    return [getTariffByIdResponse];
  } catch (nativeError) {
    const [error, status] = parseAxiosError(nativeError);

    return [null, `Не удалось получить тарифы. ${error}`, status];
  }
};

export const getCustomTariffs = async (
  signal: AbortSignal | undefined
): Promise<[ServiceKeyToPrivilegesMap | null, string?]> => {
  try {
    const customTariffsResponse = await makeRequest<
      null,
      ServiceKeyToPrivilegesMap
    >({
      method: "GET",
      url: `${API_URL}/privilege/service`,
      signal,
      useToken: true,
    });

    const tempCustomTariffsResponse = {
      ...customTariffsResponse,
      squiz: customTariffsResponse.squiz,
    };

    return [tempCustomTariffsResponse];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Не удалось получить мои тарифы. ${error}`];
  }
};

export const getTariffArray = async (tariffIds: string[] | undefined) => {
  if (!tariffIds) return null;

  const responses = await Promise.allSettled(
    tariffIds.map((tariffId) =>
      makeRequest<never, Tariff>({
        method: "GET",
        url: `${API_URL}/tariff/${tariffId}`,
        useToken: true,
      })
    )
  );

  const tariffs: Tariff[] = [];

  responses.forEach((response, index) => {
    switch (response.status) {
      case "fulfilled": {
        tariffs.push(response.value);
        break;
      }
      case "rejected": {
        const [, status] = parseAxiosError(response.reason);
        if (status === 404) removeTariffFromCart(tariffIds[index]);
        break;
      }
    }
  });

  return tariffs;
};

const apiUrl = process.env.REACT_APP_DOMAIN + "/requestquiz";
export async function sendContactFormRequest(body: {
  contact: string;
  whoami: string;
}) {
try {
  const a = await axios(apiUrl + "/callme", {
    method: "POST",
    headers: {
        "Content-Type": "application/json",
    },
    data: body,
});
return [a];
} catch (nativeError) {
  const [error] = parseAxiosError(nativeError);

  return [null, `Ошибка при отправке запроса. ${error}`];
}
}